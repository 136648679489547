$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 60
    }, 1500);
});

jQuery(document).ready(function ($) {
    $('[data-target="#nav"]').click(function () {
        if ($(this).attr('aria-expanded') === 'true') {
            $('body').css('overflow', 'scroll');
            $('body').css('position', 'relative');
        }
        else if ($(this).attr('aria-expanded') === 'false') {
            $('body').css('overflow', 'hidden');
            $('body').css('position', 'fixed');
        }
    });


    var $w = $(window),
        $canvases = $('body');
    var nav = document.querySelector("#nav");

    $w.scroll(function () {
        var d = $w.height() / 2 + $w.scrollTop();
        $canvases.each(function () {
            var $this = $(this),
                ptop = $this.offset().top,
                total = ptop + $this.height();
            if (d < 750) {
                nav.classList.remove("animated", "fadeIn", "navbar-fixed");
            }
            else {
                nav.classList.add("animated", "fadeIn", "navbar-fixed");
            }
            $this.toggleClass('active', ptop < d && total >= d);
        });
    }).scroll(); //trigger the handler when DOM is ready

});

$(function () {
    $('.nav a').on('click', function () {
        if ($('.navbar-toggle').css('display') != 'none') {
            $('.navbar-toggle').trigger("click");
        }
    });
});